@import '../variables.less';

#vrtx-periodic-table {
  .external-icon {
    &:before {
      margin-right: 5px;
      overflow: visible;
      content: url('/vrtx/dist/resources/images/external-link.png');
    }
  }
  .vrtx-periodic-table-gradient-container {
    margin-left: 50px;
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-rows: auto;

    svg {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .label-list {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      line-height: 1;
    }

    &.horizontal {
      grid-column-start: 2;
      grid-column-end: 3;

      svg {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .label-list {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        flex-direction: row;
      }
    }
  }

  section.vrtx-periodic-table-filter-list {
    color: white;
    margin: 0 0 6px 0;

    .vrtx-periodic-table-filter-list--url,
    .vrtx-periodic-table-filter-list__header {
      color: white;
      font-weight: bold;

      .font-size(18);

      a {
        text-decoration: underline;
      }
    }

    ul {
      margin-bottom: 0;
    }

    li {
      .font-size(18);
      margin-left: 0;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        width: 1em;
        height: 1em;
        background-color: red;
        float: left;
        margin: 0.55em 0.75em 0 0;
        position: relative;
        border-radius: 50%;
      }

      &.filter1 {
        &:before {
          background: @color-filter1;
        }
      }
      &.filter2 {
        &:before {
          background: @color-filter2;
        }
      }
      &.filter3 {
        &:before {
          background: @color-filter3;
        }
      }
      &.filter4 {
        &:before {
          background: @color-filter4;
        }
      }
      &.filter5 {
        &:before {
          background: @color-filter5;
        }
      }
      &.filter6 {
        &:before {
          background: @color-filter6;
        }
      }
      &.filter7 {
        &:before {
          background: @color-filter7;
        }
      }
      &.filter8 {
        &:before {
          background: @color-filter8;
        }
      }

      &.filter9 {
        &:before {
          background: @color-filter9;
        }
      }

      &.many-filter1 {
        &:before {
          background: @color-many-filter1;
        }
      }
      &.many-filter2 {
        &:before {
          background: @color-many-filter2;
        }
      }
      &.many-filter3 {
        &:before {
          background: @color-many-filter3;
        }
      }
      &.many-filter4 {
        &:before {
          background: @color-many-filter4;
        }
      }
      &.many-filter5 {
        &:before {
          background: @color-many-filter5;
        }
      }
      &.many-filter6 {
        &:before {
          background: @color-many-filter6;
        }
      }
      &.many-filter7 {
        &:before {
          background: @color-many-filter7;
        }
      }
      &.many-filter8 {
        &:before {
          background: @color-many-filter8;
        }
      }
      &.many-filter9 {
        &:before {
          background: @color-many-filter9;
        }
      }
      &.many-filter10 {
        &:before {
          background: @color-many-filter10;
        }
      }
    }

    &.horizontal {
      display: grid;
      grid-template-columns: auto 1fr;
      line-height: 1.4;

      .vrtx-periodic-table-filter-list__header {
        grid-column-start: 1;
        grid-column-end: 2;
      }

      .vrtx-periodic-table-filter-list__listing {
        margin-top: 10px;
        margin-left: 20px;
        grid-column-start: 2;
        grid-column-end: 3;
        display: inline-block;
        width: 100%;
      }

      .vrtx-periodic-table-filter-list__list-element {
        display: inline-block;
        margin-left: 30px;
      }
    }
  }

  @media @mobile {
    section.vrtx-periodic-table-filter-list.horizontal {
      margin-bottom: 50px;

      .label-list {
        font-size: 16px;
        margin-bottom: 5px;
      }
      .vrtx-periodic-table-filter-list--url {
        font-size: 16px;
      }

      .vrtx-periodic-table-filter-list__header {
        margin-top: 0;
        margin-left: 24px;
        font-size: 16px;

        grid-row-start: 1;
        grid-row-end: 2;
      }
      .vrtx-periodic-table-filter-list__listing {
        margin-left: 4px;

        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .vrtx-periodic-table-filter-list__list-element {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    .vrtx-periodic-table-gradient-container.horizontal {
      margin-top: 22px;
      margin-left: 24px;
      width: unset;

      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
}
