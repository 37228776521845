/* CSS for Periodic table */
/*
 * Utilities
 */
/*
 * Commonly used mixins
 */
.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
/*
 * Application specific configuration
 */
/*
 * Relative root for graphical profile folder.
 * Used mainly when LESS files are included from a different folder.
 */
/*
 * Utilities
 *
 */
/*
.keyframes(pulsate, {
  0%   { .transform(scale(0.5, 0.5)); opacity: 0.5; }
  50%  { opacity: 0.7;                              }
  100% { .transform(scale(1, 1)); opacity: 1;       }
});

.keyframes(pulse1, {
  0%   { .transform(scale(0.1, 0.1)); opacity: 0; }
  50%  { opacity: 1;                              }
  100% { .transform(scale(1, 1)); opacity: 0;     }
});

.keyframes(sonarEffect, {
  0% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
    .transform(scale(1.6));
  }
});
*/
/* Sonar effect: http://tympanus.net/Development/IconHoverEffects/#set-8

&:after {
  content: ' ';
  position: absolute;
  width: 39px;
  height: 39px;
  top: 2px;
  left: 2px;
  .transform(scale(0.9));
  .borderRadius(50%);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

&:hover,
&:focus {
  &:after {
    .animation(sonarEffect, .5s, ease-out);
  }
}
*/
.ui-helper-hidden-accessible,
.offscreen-screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
table.offscreen-screenreader {
  display: block;
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/
/*------------------------------------*\
# FONT
\*------------------------------------*/
/*------------------------------------*\
# PATHS
\*------------------------------------*/
/*------------------------------------*\
# DIMENSIONS
\*------------------------------------*/
/*------------------------------------*\
# MEDIA QUERIES, see also ResponsiveComponents.tsx
\*------------------------------------*/
h1,
h2,
.vrtx-periodic-table-element-toc-header,
h3 {
  font-weight: bold;
}
h1 {
  font-size: 50px;
  font-size: 5rem;
  line-height: 58px;
  line-height: 5.8rem;
  margin-top: 0;
}
h2,
.vrtx-periodic-table-element-toc-header {
  font-size: 35px;
  font-size: 3.5rem;
  line-height: 45px;
  line-height: 4.5rem;
}
h3 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 34px;
  line-height: 3.4rem;
}
caption,
h1,
h2,
h3 {
  margin: 25px 0 15px 0;
}
body {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
  line-height: 3rem;
  color: #000;
}
#main p {
  margin: 15px 0 30px 0;
}
blockquote {
  border-left: 5px solid #006ae5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 30px;
  line-height: 3rem;
  padding-left: 31px;
}
blockquote:before,
blockquote:after {
  margin-left: -35px;
}
.grid-container blockquote,
.uio-main blockquote {
  margin: 45px 0;
}
.introduction-div,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-introduction,
.vrtx-introduction-big,
.vrtx-organizational-unit-introduction {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 33px;
  line-height: 3.3rem;
  font-family: Arial, Helvetica, sans-serif;
}
.vrtx-image-listing-include-container-description,
.vrtx-imagetext,
.vrtx-img-container,
figcaption {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 30px;
  line-height: 3rem;
  color: #000;
}
body p.image img,
body figure {
  margin: 45px 0;
}
body .align-right,
body .image-right,
body .right-img,
body .vrtx-container-right,
body .vrtx-introduction-image,
body .vrtx-media-player.vrtx-container-right,
body .vrtx-media-right,
body p img.image-right {
  margin-top: 10px;
  margin-left: 45px;
}
body .align-left,
body .image-left,
body .left-img,
body .vrtx-container-left,
body .vrtx-media-left,
body .vrtx-media-player.vrtx-container-left,
body p img.image-left {
  margin-top: 10px;
  margin-right: 45px;
}
#vrtx-periodic-table-element #main #element-back-link,
body #element-back-link {
  background: #272727;
  margin-bottom: 0;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 8px 0;
}
#vrtx-periodic-table-element #main #element-back-link .row,
body #element-back-link .row {
  width: 970px;
}
#vrtx-periodic-table-element #main #element-back-link a,
body #element-back-link a {
  color: #fff;
}
#vrtx-periodic-table-element #main #element-back-link a:before,
body #element-back-link a:before {
  content: "";
  width: 0.9em;
  height: 0.9em;
  display: inline-block;
  margin-right: 15px;
  background: url('/vrtx/dist/resources/images/arrow-back.svg') no-repeat center left;
  background-size: 100% auto;
  margin-bottom: -2px;
}
#vrtx-periodic-table-element #main #total-main #vrtx-content,
#vrtx-periodic-table-element #main {
  width: 100%;
}
#vrtx-periodic-table-element #main {
  margin: 0;
}
#vrtx-periodic-table-element .vrtx-introduction,
#vrtx-periodic-table-element h1,
#vrtx-periodic-table-element #main .grid-container .row {
  width: 660px;
  margin-left: auto;
  margin-right: auto;
}
#vrtx-periodic-table-element #vrtx-periodic-table-element-header.grid-container .row {
  width: 970px;
}
#vrtx-periodic-table-element #main .grid-container .row {
  padding-top: 0;
  padding-bottom: 0;
}
#vrtx-periodic-table-element #main .grid-container .row > *:first-child {
  margin-top: 0;
}
#vrtx-periodic-table-element #main .grid-container .row > *:last-child {
  margin-bottom: 0;
}
#main #vrtx-periodic-table-element-header {
  margin-bottom: 60px;
}
#main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-information,
#main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-facts {
  margin-top: 100px;
  margin-bottom: 81px;
}
#vrtx-periodic-table-element-header-information,
#vrtx-periodic-table-element-header-facts {
  margin-top: 100px;
}
#vrtx-periodic-table-element-header-information {
  width: 219px;
  height: 219px;
  border: 1px solid #000;
  background-color: #ffffff;
  float: left;
  font-weight: bold;
  text-align: center;
}
#vrtx-periodic-table-element-header-atomic-number,
#vrtx-periodic-table-element-header-symbol,
#vrtx-periodic-table-element-header-name {
  display: block;
  text-align: center;
  line-height: 1;
}
#vrtx-periodic-table-element-header-atomic-number {
  margin-top: 10px;
}
#vrtx-periodic-table-element-header-symbol {
  margin-top: 15px;
}
#vrtx-periodic-table-element-header-name {
  margin-top: 22px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}
#vrtx-periodic-table-element-header-symbol {
  font-size: 75px;
  font-size: 7.5rem;
}
#vrtx-periodic-table-element-header-atomic-number,
#vrtx-periodic-table-element-header-name {
  font-size: 36px;
  font-size: 3.6rem;
}
#vrtx-periodic-table-element-header-facts {
  width: 613px;
  min-height: 219px;
  position: absolute;
  left: 219px;
  z-index: 1;
  margin-left: 63px;
  float: left;
  background-color: #006ae5;
  color: #fff;
  padding: 26px 39px 17px 39px;
  font-size: 20px;
  font-size: 2rem;
}
#vrtx-periodic-table-element-header-facts a {
  color: #fff;
}
#vrtx-periodic-table-element-header-facts dl {
  overflow: hidden;
}
#vrtx-periodic-table-element-header-facts dl:nth-of-type(1) dt {
  min-width: 200px;
}
#vrtx-periodic-table-element-header-facts dl:nth-of-type(2) dt {
  min-width: 115px;
}
#vrtx-periodic-table-element-header-facts dl:nth-of-type(3) dt {
  min-width: 255px;
}
#vrtx-periodic-table-element-header-facts dl ~ dl {
  border-top: solid 1px #ffffff;
  margin-top: 15px;
  padding-top: 15px;
  display: none;
}
#vrtx-periodic-table-element-header-facts dt,
#vrtx-periodic-table-element-header-facts dd {
  float: left;
}
#vrtx-periodic-table-element-header-facts dt {
  clear: both;
  margin-bottom: 15px;
}
#vrtx-periodic-table-element-header-facts dt:last-of-type {
  margin-bottom: 0;
}
#vrtx-periodic-table-element-header-facts dd {
  margin: 0;
}
#vrtx-periodic-table-element-header-facts-show-more,
#vrtx-periodic-table-element-header-facts-show-less {
  display: block;
  clear: left;
  float: right;
  margin-top: -30px;
  text-decoration: underline;
}
#vrtx-periodic-table-element-header-facts-show-more:hover,
#vrtx-periodic-table-element-header-facts-show-less:hover,
#vrtx-periodic-table-element-header-facts-show-more:focus,
#vrtx-periodic-table-element-header-facts-show-less:focus {
  text-decoration: none;
}
#vrtx-periodic-table-element-header-facts-show-more:after,
#vrtx-periodic-table-element-header-facts-show-less:after {
  content: "";
  background: url('/vrtx/dist/resources/images/chevron-down-white.svg') no-repeat 0 0;
  background-size: 100% auto;
  height: 1.2em;
  width: 1.2em;
  margin-bottom: -0.4em;
  margin-left: 15px;
  display: inline-block;
}
#vrtx-periodic-table-element-header-facts-show-less {
  margin-top: 10px;
  margin-bottom: 10px;
}
#vrtx-periodic-table-element-header-facts-show-less:after {
  background-image: url('/vrtx/dist/resources/images/chevron-up-white.svg');
  background-size: 100% auto;
}
#vrtx-periodic-table-element-header-facts-show-less {
  display: none;
}
#vrtx-periodic-table-element-header .row {
  position: relative;
}
#vrtx-periodic-table-element-header-picture-credit {
  position: absolute;
  bottom: -40px;
  width: 100%;
  left: 0;
  text-align: right;
  color: #949494;
}
#vrtx-periodic-table-element-header-picture-credit:before {
  content: '';
  display: inline-block;
  height: 1.2em;
  width: 1.4em;
  position: relative;
  margin-right: 5px;
  background: url('/vrtx/dist/resources/images/foto.svg') no-repeat 0 0;
  background-size: auto 80%;
  top: 0.8ex;
}
.vrtx-periodic-table-element-toc-header {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 0;
  padding-bottom: 10px;
  /*
  border-left: 5px solid @color-elements-blue-bg;
  padding-left: 50px;
  */
}
.vrtx-periodic-table-element-toc {
  margin: 0 auto 100px auto;
  overflow: hidden;
  width: 660px;
  /*
  border-left: 5px solid @color-elements-blue-bg;
  padding-left: 50px;
  */
}
.vrtx-periodic-table-element-toc ul li {
  font-size: 20px;
  font-size: 2rem;
  margin: 0 0 20px 0;
  width: 50%;
}
.vrtx-periodic-table-element-toc ul li:before {
  display: none;
}
.vrtx-periodic-table-element-toc ul li a:before {
  content: "";
  height: 1.5em;
  width: 1.5em;
  display: inline-block;
  background: url('/vrtx/dist/resources/images/arrow-down.svg') no-repeat 0 0.5ex;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-right: 7px;
}
.vrtx-periodic-table-element-toc ul li:nth-child(odd) {
  clear: both;
  float: left;
}
.vrtx-periodic-table-element-toc ul li:nth-child(even) {
  float: right;
}
.vrtx-periodic-table-element-toc ul li:before {
  content: "";
}
.vrtx-periodic-table-element-content-read-more {
  font-weight: bold;
}
.vrtx-periodic-table-element-content-read-more[aria-expanded='true']:after {
  background: url('/vrtx/dist/resources/images/chevron-up-blue.svg') no-repeat 0 0;
  background-size: 100% auto;
}
.vrtx-periodic-table-element-content-read-more:after {
  content: "";
  background: url('/vrtx/dist/resources/images/chevron-down-blue.svg') no-repeat 0 0;
  background-size: 100% auto;
  height: 1em;
  width: 1em;
  margin-bottom: -0.4em;
  margin-left: 15px;
  display: inline-block;
}
.vrtx-periodic-table-element-content-read-more ~ .vrtx-periodic-table-element-content-read-more--hidden {
  display: none !important;
}
#main #vrtx-periodic-table-element-resource-links {
  background-color: #272727;
  padding: 60px 0;
}
#main #vrtx-periodic-table-element-resource-links #vrtx-periodic-table-element-resource-links-title {
  font-size: 35px;
  font-size: 3.5rem;
  font-weight: bold;
  display: block;
  margin-bottom: 40px;
}
#main #vrtx-periodic-table-element-resource-links,
#main #vrtx-periodic-table-element-resource-links a {
  color: #fff;
}
#main #vrtx-periodic-table-element-resource-links a {
  display: block;
  padding: 25px 0 25px 0;
  border-top: 2px solid #006ae5;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 34px;
  line-height: 3.4rem;
  position: relative;
  padding-right: 55px;
}
#main #vrtx-periodic-table-element-resource-links a:after {
  content: "";
  height: 1.5em;
  width: 1.5em;
  display: inline-block;
  background: url('/vrtx/dist/resources/images/arrow-forward-blue.svg') no-repeat center right;
  background-size: 100% auto;
  position: absolute;
  top: 22px;
  right: 0;
}
#main #vrtx-periodic-table-element-resource-links .row > span:last-child a {
  border-bottom: 2px solid #006ae5;
}
#main #vrtx-periodic-table-element-resource-links:last-of-type {
  margin-bottom: 0;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  h1 {
    font-size: 40px;
    font-size: 3.28rem;
    line-height: 48px;
    line-height: 3.936rem;
  }
  h2,
  .vrtx-periodic-table-element-toc-header {
    font-size: 30px;
    font-size: 2.46rem;
    line-height: 38px;
    line-height: 3.1159999999999997rem;
  }
  h3 {
    font-size: 22px;
    font-size: 1.8039999999999998rem;
    line-height: 32px;
    line-height: 2.6239999999999997rem;
  }
  body {
    font-size: 18px;
    font-size: 1.476rem;
    line-height: 30px;
    line-height: 2.46rem;
    color: #000;
  }
  blockquote {
    font-size: 22px;
    font-size: 1.8039999999999998rem;
    line-height: 30px;
    line-height: 2.46rem;
  }
  .introduction-div,
  .vrtx-frontpage-box.introduction .vrtx-box-content,
  .vrtx-introduction,
  .vrtx-introduction-big,
  .vrtx-organizational-unit-introduction {
    font-size: 20px;
    font-size: 1.64rem;
    line-height: 33px;
    line-height: 2.706rem;
  }
  #main {
    padding: 0;
  }
  #main #vrtx-periodic-table-element-header {
    background-size: auto 219px;
    background-position: 0 0;
  }
  #vrtx-periodic-table-element #main #total-main #vrtx-content,
  #vrtx-periodic-table-element #main {
    width: 100%;
  }
  #vrtx-periodic-table-element #main {
    margin-top: 0;
    margin-bottom: 0;
  }
  #vrtx-periodic-table-element h1,
  #vrtx-periodic-table-element .vrtx-introduction,
  #vrtx-periodic-table-element #main .grid-container .row {
    width: 100%;
  }
  #vrtx-periodic-table-element #main .grid-container .row {
    margin-left: 0;
    margin-right: 0;
  }
  #vrtx-periodic-table-element h1,
  #vrtx-periodic-table-element .vrtx-introduction {
    padding-left: 15px;
    padding-right: 15px;
  }
  #vrtx-periodic-table-element #vrtx-periodic-table-element-header.grid-container .row {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  #vrtx-periodic-table-element #vrtx-periodic-table-element-header.grid-container .row #vrtx-periodic-table-element-header-information {
    order: 1;
  }
  #vrtx-periodic-table-element #vrtx-periodic-table-element-header.grid-container .row #vrtx-periodic-table-element-header-picture-credit {
    order: 2;
    position: static;
  }
  #vrtx-periodic-table-element #vrtx-periodic-table-element-header.grid-container .row #vrtx-periodic-table-element-header-facts {
    order: 3;
  }
  #vrtx-periodic-table-element #main #element-back-link {
    margin-top: 0;
    padding: 6px 0;
    font-size: 18px;
    font-size: 1.476rem;
  }
  #vrtx-periodic-table-element #main #element-back-link .row {
    padding: 0 15px;
  }
  #main #vrtx-periodic-table-element-header {
    margin-bottom: 0px;
  }
  #main #vrtx-periodic-table-element-header #vrtx-periodic-table-element-header-information,
  #main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-information {
    margin-top: 30px;
    margin-bottom: 30px;
    float: left;
  }
  #main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-information {
    margin-bottom: 70px;
  }
  #main #vrtx-periodic-table-element-header #vrtx-periodic-table-element-header-facts,
  #main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-facts {
    float: none;
    clear: both;
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
  }
  #main #vrtx-periodic-table-element-header.has-image #vrtx-periodic-table-element-header-facts {
    margin-top: 20px;
  }
  #vrtx-periodic-table-element-header-facts {
    font-size: 16px;
    font-size: 1.3119999999999998rem;
    line-height: 26px;
    line-height: 2.6rem;
    position: static;
    min-height: 0;
    padding: 12px 20px 10px 20px;
    margin: 0;
  }
  #vrtx-periodic-table-element-header-facts > a {
    font-weight: bold;
    font-size: 18px;
    font-size: 1.476rem;
  }
  #vrtx-periodic-table-element-header-facts > a.visible:after {
    background-image: url('/vrtx/dist/resources/images/chevron-up-white.svg');
  }
  #vrtx-periodic-table-element-header-facts > a:after {
    content: "";
    background: url('/vrtx/dist/resources/images/chevron-down-white.svg') no-repeat 0 0;
    background-size: 100% auto;
    height: 1em;
    width: 1em;
    margin-bottom: -0.4em;
    margin-left: 15px;
    display: inline-block;
  }
  #vrtx-periodic-table-element-header-facts dt {
    margin-bottom: 10px;
  }
  #vrtx-periodic-table-element-header-facts dl {
    overflow: hidden;
  }
  #vrtx-periodic-table-element-header-facts dl:nth-of-type(1) {
    border-top: solid 1px #ffffff;
    margin-top: 15px;
    padding-top: 15px;
  }
  #vrtx-periodic-table-element-header-facts dl:nth-of-type(1) dt {
    min-width: 150px;
  }
  #vrtx-periodic-table-element-header-facts dl:nth-of-type(2) dt {
    min-width: 85px;
  }
  #vrtx-periodic-table-element-header-facts dl:nth-of-type(3) dt {
    min-width: 190px;
  }
  #vrtx-periodic-table-element-header-facts-show-more,
  #vrtx-periodic-table-element-header-facts-show-less {
    display: none !important;
  }
  #vrtx-periodic-table-element-header-information {
    width: 112px;
    height: 112px;
  }
  #vrtx-periodic-table-element-header-atomic-number {
    margin-top: 5px;
  }
  #vrtx-periodic-table-element-header-symbol {
    margin-top: 10px;
  }
  #vrtx-periodic-table-element-header-name {
    margin-top: 10px;
  }
  #vrtx-periodic-table-element-header-symbol {
    font-size: 40px;
    font-size: 3.28rem;
  }
  #vrtx-periodic-table-element-header-atomic-number,
  #vrtx-periodic-table-element-header-name {
    font-size: 20px;
    font-size: 1.64rem;
  }
  #vrtx-periodic-table-element-header-picture-credit {
    padding: 0 15px;
    bottom: -20px;
    font-size: 16px;
    font-size: 1.3119999999999998rem;
  }
  .vrtx-periodic-table-element-toc {
    padding-left: 0;
    margin: 0 15px 50px 15px;
    width: auto;
  }
  .vrtx-periodic-table-element-toc ul li {
    font-size: 18px;
    font-size: 1.8rem;
    width: auto;
  }
  .vrtx-periodic-table-element-toc ul li:nth-child(2n+1),
  .vrtx-periodic-table-element-toc ul li:nth-child(2n) {
    float: none;
  }
  #main #vrtx-periodic-table-element-resource-links {
    background-color: #272727;
    padding: 50px 0;
  }
  #main #vrtx-periodic-table-element-resource-links #vrtx-periodic-table-element-resource-links-title {
    font-size: 26px;
    font-size: 2.132rem;
    margin-bottom: 30px;
  }
  #main #vrtx-periodic-table-element-resource-links a {
    font-size: 20px;
    font-size: 1.64rem;
    line-height: 30px;
    line-height: 2.46rem;
    padding-right: 40px;
  }
}
@media print {
  #element-back-link {
    display: none;
  }
}
#vrtx-periodic-table-element-listing #footer-wrapper {
  border-top: 1px solid #6f6f6f;
}
#vrtx-periodic-table-element-listing #main #total-main #vrtx-content,
#vrtx-periodic-table-element-listing #main {
  width: 100%;
}
#vrtx-periodic-table-element-listing #main {
  margin-top: 0;
  margin-bottom: 0;
}
#vrtx-periodic-table-element-listing #main .grid-container .row {
  width: 970px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #main .grid-container .row {
    width: 828px;
  }
}
#vrtx-periodic-table-element-listing #main .grid-container .row.periodic-table {
  width: 997.1px;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #main .grid-container .row.periodic-table {
    width: 855.1px;
  }
}
#vrtx-periodic-table-element-listing #main .grid-container .row.element-info {
  width: 100%;
}
#vrtx-periodic-table-element-listing #main .grid-container.grid-color-black {
  background: #272727;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table-element-header.grid-container .row {
  width: 970px;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table-element-header.grid-container .row {
    width: 828px;
  }
}
#vrtx-periodic-table-element-listing .vrtx-frontpage-box.grey-box,
#vrtx-periodic-table-element-listing .vrtx-frontpage-box.grey-box-light,
#vrtx-periodic-table-element-listing .vrtx-frontpage-box.grey-clip-box {
  padding-left: 0;
  padding-right: 0;
}
#vrtx-periodic-table-element-listing .top-row-controllers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0;
  margin-bottom: 0;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table .vrtx-box-content {
  display: block;
  margin-bottom: 60px;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table #vrtx-periodic-table-main {
  margin-bottom: 5px;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table #vrtx-periodic-table-separate {
  margin-top: 20px;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table,
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td,
#vrtx-periodic-table-element-listing #vrtx-periodic-table table th {
  border: none;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table {
  width: 1%;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table #vrtx-periodic-table-separate {
  margin-left: 108px;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table #vrtx-periodic-table-separate {
    margin-left: 94px;
  }
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td {
  padding: 0;
  margin: 0;
  border: 2px solid transparent;
  border-width: 0 2px 2px 0;
  line-height: 1;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td.vrtx-periodic-table-element-special {
  font-size: 18px;
  font-size: 1.8rem;
  color: white;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td.vrtx-periodic-table-element-special:not(.contains-expand-button) {
  padding: 8px 0 0 8px;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td.vrtx-periodic-table-element-special .vrtx-periodic-table-expand-separate-table {
  border: none;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-weight: normal;
  background: #272727;
  color: white;
  line-height: 1.2;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td.numbering-info {
  width: 52px;
  color: white;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  padding-top: 8px;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table table td.numbering-info {
    width: 45px;
  }
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table table td.numbering-info.table-period {
  padding: 15px 15px 0 0;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container {
  display: flex;
  justify-content: center;
  position: relative;
  /* IE center HACK */
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container .vrtx-periodic-table-element-filter-info {
  border-radius: 4px;
  min-width: 88.4px;
  min-height: 25.25714286px;
  margin-top: 74.2px;
  background-color: white;
  text-align: center;
  position: absolute;
  display: none;
  padding: 10px;
  white-space: nowrap;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container .vrtx-periodic-table-element-filter-info {
    min-width: 76.5px;
    min-height: 21.85714286px;
    margin-top: 64.75px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container .vrtx-periodic-table-element-filter-info {
    transform: translateX(-50%);
    /* IE center HACK */
    left: 50%;
    /* IE center HACK */
  }
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container:focus.vrtx-periodic-table-element-filter-info,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-container:hover .vrtx-periodic-table-element-filter-info {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element {
  border: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-weight: normal;
  background: #fff;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: 1.3;
  color: #000;
  cursor: pointer;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element {
    width: 45px;
    height: 45px;
  }
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.selected {
  border: 3px solid #006ae5;
  z-index: 1;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-full-name {
  display: none;
  text-align: center;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-atomic-number,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-name {
  display: block;
  text-align: center;
  font-weight: bold;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-atomic-number {
  font-size: 14px;
  font-size: 1.4rem;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-name {
  font-size: 24px;
  font-size: 2.4rem;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element .vrtx-periodic-table-element-name {
    font-size: 22px;
    font-size: 2.2rem;
  }
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover {
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  border: 2px solid #272727;
  text-decoration: none;
  z-index: 9999;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus.selected,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover.selected {
  border-color: #006ae5;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus .vrtx-periodic-table-element-atomic-number,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover .vrtx-periodic-table-element-atomic-number {
  font-size: 8px;
  font-size: 0.8rem;
  margin-top: 3px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus .vrtx-periodic-table-element-name,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover .vrtx-periodic-table-element-name {
  font-size: 18px;
  font-size: 1.8rem;
}
@media only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus .vrtx-periodic-table-element-name,
  #vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover .vrtx-periodic-table-element-name {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.2;
  }
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:focus .vrtx-periodic-table-element-full-name,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element:hover .vrtx-periodic-table-element-full-name {
  display: block;
  font-size: 7px;
  font-size: 0.7rem;
}
#vrtx-periodic-table-element-listing .table-mobile-element.subdued,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.subdued {
  opacity: 0.4;
}
#vrtx-periodic-table-element-listing .table-mobile-element.no-filter,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.no-filter {
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #dedede 10px, #dedede 20px);
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter1,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter1 {
  background: #f0f0f0;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter2,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter2 {
  background: #d7d7d7;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter3,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter3 {
  background: #c9c9c9;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter4,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter4 {
  background: #b8b8b8;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter5,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter5 {
  background: #8d8d8d;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter6,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter6 {
  background: #997d9a;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter7,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter7 {
  background: #ad61b0;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter8,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter8 {
  background: #d230d8;
}
#vrtx-periodic-table-element-listing .table-mobile-element.filter9,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.filter9 {
  background: #f500ff;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter1,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter1 {
  background: #f0f0f0;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter2,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter2 {
  background: #c9c9c9;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter3,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter3 {
  background: #b1ffeb;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter4,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter4 {
  background: #73f8d6;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter5,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter5 {
  background: #00ffbd;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter6,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter6 {
  background: #25d9ce;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter7,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter7 {
  background: #49b3d1;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter8,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter8 {
  background: #7c7ede;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter9,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter9 {
  background: #b444ed;
}
#vrtx-periodic-table-element-listing .table-mobile-element.many-filter10,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element.many-filter10 {
  background: #f500ff;
}
#vrtx-periodic-table-element-listing .close-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#vrtx-periodic-table-element-listing .close-button__button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 18px;
  font-size: 1.8rem;
}
#vrtx-periodic-table-element-listing .close-button__button:focus {
  outline: 1px solid white;
}
#vrtx-periodic-table-element-listing .close-button--with-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  padding-bottom: 10px;
  margin-bottom: 25px;
}
#vrtx-periodic-table-element-listing .close-button__icon {
  margin-left: 7px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info-container {
  position: relative;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info-container .arrow-box--group-info,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info-container .arrow-box--period-info {
  font-size: 16px;
  font-size: 1.6rem;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info-container .arrow-box--group-info .close-button,
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info-container .arrow-box--period-info .close-button {
  margin-bottom: 11px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info span {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: bold;
  color: #efefef;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.group {
  margin-left: 27px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.group span {
  border-bottom: 1px solid #006ae5;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.group img {
  margin: 0 10px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.period {
  flex-direction: column;
  position: absolute;
  margin-left: -50px;
  margin-top: 30px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.period span {
  border-left: 1px solid #006ae5;
  writing-mode: vertical-rl;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-info.period img {
  margin: 10px 0;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  #head {
    background-color: #363534;
  }
  #footer-wrapper {
    padding-left: 20px;
  }
  #main #total-main #vrtx-content {
    height: 100vh;
  }
  #main #total-main #vrtx-content:not(.popup-is-active) {
    display: contents;
  }
  #vrtx-periodic-table-element-listing #fullscreen-button,
  #vrtx-periodic-table-element-listing .periodic-table,
  #vrtx-periodic-table-element-listing #vrtx-periodic-table-separate {
    display: none;
  }
  #vrtx-periodic-table-element-listing #offcanvas-outer-wrapper #head-wrapper {
    border-bottom: none;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table .showing-element-info {
    height: 100vh;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-color-black {
    height: 100%;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table-element-listing #main .grid-container .row {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element-container {
    width: 70px;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element {
    width: 70px;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element .vrtx-periodic-table-element-full-name {
    display: inline;
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element .vrtx-periodic-table-element-atomic-number {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: right;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element .vrtx-periodic-table-element-name {
    font-size: 22px;
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) and only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element-container {
    width: 63px;
  }
  #vrtx-periodic-table-element-listing #periodic-table .vrtx-periodic-table-element {
    width: 63px;
  }
}
#vrtx-periodic-table .filter-menu a {
  font-size: 18px;
  color: white;
  cursor: pointer;
}
#vrtx-periodic-table .filter-menu .remove-filter-button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0;
  text-decoration: underline;
  color: white;
  font-weight: normal;
}
#vrtx-periodic-table .filter-menu .remove-filter-button:hover,
#vrtx-periodic-table .filter-menu .remove-filter-button:focus {
  font-weight: bold;
}
#vrtx-periodic-table .filter-menu .periodic-table-filter-category-title {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-weight: bold;
  font-size: 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  margin-top: 20px;
  width: 100%;
  color: white;
}
#vrtx-periodic-table .filter-menu .periodic-table-filter-category-title.expanded {
  border-bottom: 2px solid #006ae5;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type {
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type .periodic-table-filter-name {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  margin-left: 30px;
  border-bottom: 1px solid #006ae5;
  font-weight: normal;
  color: white;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type .periodic-table-filter-name:hover,
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type .periodic-table-filter-name:focus {
  font-weight: bold;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type .periodic-table-filter-name--scale {
  font-size: 16px;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type .periodic-table-filter-name--scale {
  margin-left: 0px;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type:before {
  margin-top: 4px;
  margin-left: 0;
  overflow: visible;
  content: url('/vrtx/dist/resources/images/unselected.png');
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type.disabled {
  color: #bfbfbf;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type.disabled:before {
  content: url('/vrtx/dist/resources/images/unselected_grey.png');
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type.disabled .periodic-table-filter-name {
  color: #bfbfbf;
  border-color: #997d9a;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type.selected {
  font-weight: bold;
}
#vrtx-periodic-table .filter-menu ul > li.periodic-table-filter-type.selected:before {
  content: url('/vrtx/dist/resources/images/radiobutton.png');
}
#vrtx-periodic-table .filter-menu .periodic-table-select-temperature-scale > li {
  padding-left: 30px;
  font-weight: normal;
}
#vrtx-periodic-table .filter-menu .periodic-table-select-temperature-scale > li:hover,
#vrtx-periodic-table .filter-menu .periodic-table-select-temperature-scale > li:focus {
  font-weight: bold;
}
#vrtx-periodic-table .filter-menu-button-container {
  display: flex;
  height: min-content;
  justify-content: flex-end;
  position: relative;
}
#vrtx-periodic-table button#filter-menu-button {
  background-color: transparent;
  border: none;
  margin: 0;
  display: flex;
  align-items: center;
  margin: 30px 0;
  padding-right: 19px;
  margin-right: -19px;
}
#vrtx-periodic-table button#filter-menu-button:focus {
  outline: 1px solid white;
}
#vrtx-periodic-table button#filter-menu-button .filter-menu-button__label {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 2px solid #006ae5;
  color: #efefef;
}
#vrtx-periodic-table button#filter-menu-button .filter-menu-button__icon {
  margin: 0px 8px 0px 0px;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-periodic-table button#filter-menu-button {
    margin-top: 0;
    margin-bottom: 0;
  }
  #vrtx-periodic-table button#filter-menu-button .filter-menu-button__label {
    font-size: 16px;
  }
  #vrtx-periodic-table button#filter-menu-button .filter-menu-button__icon {
    width: 20px;
    height: 21px;
  }
  #vrtx-periodic-table .filter-menu-button-container {
    width: 100vw;
  }
  #vrtx-periodic-table .periodic-table-filter-type__desktop-only {
    margin: 0 0 0 30px;
    font-style: italic;
  }
}
#vrtx-periodic-table-element-listing dt,
#vrtx-periodic-table-element-listing dd {
  float: left;
}
#vrtx-periodic-table-element-listing dt {
  clear: both;
}
#vrtx-periodic-table-element-listing dt:last-of-type {
  margin-bottom: 0;
}
#vrtx-periodic-table-element-listing dd {
  margin: 0;
  margin-bottom: 17px;
  font-weight: normal;
}
#vrtx-periodic-table-element-listing dd:last-of-type {
  margin-bottom: 0;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-static {
  background: white;
  color: black;
  text-align: center;
  font-weight: bold;
  width: 153px;
  height: 153px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-static .vrtx-periodic-table-element-atomic-number {
  font-size: 20px;
  font-size: 2rem;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-static .vrtx-periodic-table-element-name {
  font-size: 64px;
  font-size: 6.4rem;
}
#vrtx-periodic-table-element-listing .vrtx-periodic-table-element-static .vrtx-periodic-table-element-full-name {
  font-size: 20px;
  font-size: 2rem;
}
#vrtx-periodic-table-element-listing a.read-more-button {
  font-size: 26px;
  font-size: 2.6rem;
  color: white;
  margin-right: 25px;
  padding: 5px;
  text-decoration: underline;
}
#vrtx-periodic-table-element-listing a.read-more-button:focus {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
  outline: 1px solid white;
}
#vrtx-periodic-table-element-listing .row {
  padding: 0;
}
#vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info {
  /* Hack to get full width (does not work with flex):
    width: 100vw;
    position: relative;
    padding-left: calc(~'(100vw - 970px) / 2');
    padding-right: calc(~'(100vw - 970px) / 2');*/
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 50px;
  background-color: #006ae5;
  color: white;
}
@media only screen and (min-width: 16cm) and (orientation: portrait), only screen and (min-width: 19cm) and (orientation: landscape) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner {
    width: 970px;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article {
    display: grid;
    grid-template-columns: 1fr 1.2fr 2fr;
    font-size: 20px;
    font-size: 2rem;
    justify-self: center;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section {
    border-right: 1px solid white;
    margin-right: 35px;
    padding-right: 10px;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section .element-property {
    margin-right: 5px;
    font-weight: bold;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box .element-property {
    margin-top: 17px;
    display: inline-block;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.fact-listing {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.introduction {
    grid-column-start: 3;
    grid-column-end: 4;
    border: none;
    margin-right: 0;
  }
}
@media only screen and (min-width: 16cm) and (orientation: portrait), only screen and (min-width: 19cm) and (orientation: landscape) and only screen and (min-width: 16cm) and (orientation: portrait) and (max-width: 1249px), only screen and (min-width: 19cm) and (orientation: landscape) and (max-width: 1249px) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999;
    left: 0;
    top: 0;
    overflow-y: scroll;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info dl {
    overflow: hidden;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info dl dt {
    min-width: 167px;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .close-button {
    margin-right: 13px;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article {
    display: grid;
    grid-template-rows: auto auto auto;
    font-size: 16px;
    font-size: 1.6rem;
    justify-self: center;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section {
    border-bottom: 1px solid white;
    margin-bottom: 25px;
    padding-bottom: 25px;
    margin-left: 30px;
    margin-right: 50px;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box {
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box .vrtx-periodic-table-element-static {
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box .element-property {
    display: inline-block;
    margin-right: 5px;
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    font-weight: bold;
    text-align: center;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.element-box .element-box__element-value {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: center;
    font-style: italic;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.fact-listing {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  #vrtx-periodic-table-element-listing #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info .vrtx-periodic-table-element-info-inner article section.introduction {
    grid-row-start: 3;
    grid-row-end: 4;
    border: none;
  }
}
#vrtx-periodic-table .external-icon:before {
  margin-right: 5px;
  overflow: visible;
  content: url('/vrtx/dist/resources/images/external-link.png');
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container {
  margin-left: 50px;
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: auto;
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container svg {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container .label-list {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  line-height: 1;
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container.horizontal {
  grid-column-start: 2;
  grid-column-end: 3;
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container.horizontal svg {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}
#vrtx-periodic-table .vrtx-periodic-table-gradient-container.horizontal .label-list {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  flex-direction: row;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list {
  color: white;
  margin: 0 0 6px 0;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list .vrtx-periodic-table-filter-list--url,
#vrtx-periodic-table section.vrtx-periodic-table-filter-list .vrtx-periodic-table-filter-list__header {
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list .vrtx-periodic-table-filter-list--url a,
#vrtx-periodic-table section.vrtx-periodic-table-filter-list .vrtx-periodic-table-filter-list__header a {
  text-decoration: underline;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list ul {
  margin-bottom: 0;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li {
  font-size: 18px;
  font-size: 1.8rem;
  margin-left: 0;
  display: flex;
  align-items: center;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li:before {
  content: '';
  width: 1em;
  height: 1em;
  background-color: red;
  float: left;
  margin: 0.55em 0.75em 0 0;
  position: relative;
  border-radius: 50%;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter1:before {
  background: #f0f0f0;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter2:before {
  background: #d7d7d7;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter3:before {
  background: #c9c9c9;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter4:before {
  background: #b8b8b8;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter5:before {
  background: #8d8d8d;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter6:before {
  background: #997d9a;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter7:before {
  background: #ad61b0;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter8:before {
  background: #d230d8;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.filter9:before {
  background: #f500ff;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter1:before {
  background: #f0f0f0;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter2:before {
  background: #c9c9c9;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter3:before {
  background: #b1ffeb;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter4:before {
  background: #73f8d6;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter5:before {
  background: #00ffbd;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter6:before {
  background: #25d9ce;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter7:before {
  background: #49b3d1;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter8:before {
  background: #7c7ede;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter9:before {
  background: #b444ed;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list li.many-filter10:before {
  background: #f500ff;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal {
  display: grid;
  grid-template-columns: auto 1fr;
  line-height: 1.4;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__header {
  grid-column-start: 1;
  grid-column-end: 2;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__listing {
  margin-top: 10px;
  margin-left: 20px;
  grid-column-start: 2;
  grid-column-end: 3;
  display: inline-block;
  width: 100%;
}
#vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__list-element {
  display: inline-block;
  margin-left: 30px;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal {
    margin-bottom: 50px;
  }
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .label-list {
    font-size: 16px;
    margin-bottom: 5px;
  }
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list--url {
    font-size: 16px;
  }
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__header {
    margin-top: 0;
    margin-left: 24px;
    font-size: 16px;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__listing {
    margin-left: 4px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  #vrtx-periodic-table section.vrtx-periodic-table-filter-list.horizontal .vrtx-periodic-table-filter-list__list-element {
    margin-left: 20px;
    font-size: 14px;
  }
  #vrtx-periodic-table .vrtx-periodic-table-gradient-container.horizontal {
    margin-top: 22px;
    margin-left: 24px;
    width: unset;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}
#fullscreen-button {
  padding: 0;
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  margin: 2px;
  border-radius: 26px;
  transition: 0.2s;
  background-color: transparent !important;
}
#fullscreen-button:hover,
#fullscreen-button:focus {
  border: 1px solid white;
}
#fullscreen-button.fullscreen-off:hover,
#fullscreen-button.fullscreen-off:focus {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
#fullscreen-button.fullscreen-on:hover,
#fullscreen-button.fullscreen-on:focus {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
#fullscreen-button .fullscreen-logo {
  fill: white;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
}
/* CSS arrow is from here: http://www.cssarrowplease.com/ */
.closable-popup {
  border-radius: 0px;
  pointer-events: auto;
  border: 2px solid #006ae5;
  background-color: #272727;
  color: white;
  font-family: Helvetica;
  width: 350px;
  z-index: 10000;
  position: absolute;
  padding: 21px;
}
.closable-popup.filter-menu {
  top: 90px;
  right: -35px;
  width: 400px;
}
.closable-popup.arrow-box--group-info {
  top: 50px;
  left: 30px;
}
.closable-popup.arrow-box--period-info {
  left: 0px;
}
.closable-popup.arrow-box--filter-info {
  margin-right: 180px;
  margin-left: -180px;
  border: none;
  background-color: #006ae5;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .closable-popup.arrow-box--filter-info {
    margin-left: -530px;
    margin-top: -70px;
  }
}
.closable-popup.arrow-box--filter-info:after {
  border-left-color: #006ae5;
}
.arrow-box:after,
.arrow-box:before {
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow-box--top:after,
.arrow-box--top:before {
  bottom: 100%;
  left: 62%;
}
.arrow-box--left:after,
.arrow-box--left:before {
  right: 100%;
  top: 50%;
}
.arrow-box--right:after,
.arrow-box--right:before {
  left: 100%;
  top: 50%;
}
.arrow-box--group-info:after,
.arrow-box--group-info:before {
  left: 21%;
}
.arrow-box--period-info:after,
.arrow-box--period-info:before {
  top: 55%;
}
.arrow-box:after {
  border-color: rgba(39, 39, 39, 0);
  border-width: 15px;
}
.arrow-box--top:after {
  border-bottom-color: #272727;
  margin-left: -15px;
}
.arrow-box--left:after {
  border-right-color: #272727;
  margin-top: -15px;
}
.arrow-box--right:after {
  border-left-color: #272727;
  margin-top: -15px;
}
.arrow-box:before {
  border-color: rgba(0, 118, 255, 0);
  border-width: 19px;
}
.arrow-box--top:before {
  border-bottom-color: #006ae5;
  margin-left: -19px;
}
.arrow-box--left:before {
  border-right-color: #006ae5;
  margin-top: -19px;
}
.arrow-box--right:before {
  border-left-color: #006ae5;
  margin-top: -19px;
}
.mobile-closable-popup {
  position: absolute;
  left: 0;
  top: 0px;
  overflow-y: scroll;
  border-radius: 0px;
  pointer-events: auto;
  border: 3px solid #006ae5;
  background-color: #272727;
  color: white;
  font-family: Helvetica;
  z-index: 10000;
  padding: 21px;
  height: 100vh;
  width: 100vw;
}
.ui-center {
  text-align: center;
}
.top-row-controllers {
  padding-right: 15px;
}
.carousel-container {
  position: relative;
  height: 100%;
  max-width: 960px;
  margin: 0px auto 50px auto;
  overflow: hidden;
  touch-action: pan-y;
}
.carousel-track {
  display: flex;
}
.carousel-card {
  flex: 0 0 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
}
.carousel-card-inner,
.table-mobile-element {
  display: flex;
  flex-direction: column;
  color: black;
  width: 70px;
}
.carousel-card-inner {
  height: 378px;
  justify-content: flex-end;
  margin-left: 2px;
}
.table-mobile-element {
  position: relative;
  height: 52px;
  background-color: white;
  margin-top: 2px;
}
.table-mobile-element.table-mobile-element--center-element {
  border: 1px solid white;
}
.table-mobile-element:not(.table-mobile-element--center-element) {
  opacity: 0.4;
}
.table-mobile-element .vrtx-periodic-table-element-atomic-number {
  position: absolute;
  right: 4px;
  font-size: 14px;
  line-height: 1.6;
}
.table-mobile-element .vrtx-periodic-table-element-name {
  margin-top: 8px;
  font-size: 22px;
}
.table-mobile-element .vrtx-periodic-table-element-full-name {
  font-size: 12px;
  line-height: 1;
}
.table-mobile-element--fblock {
  background-color: black;
  border: 1px solid white;
}
.table-mobile-element__fblock-content {
  color: white;
  font-size: 16px;
  line-height: 1.6;
}
.table-mobile-element__fblock-content:after {
  content: url('/vrtx/dist/resources/images/pil.png');
  width: 14px;
}
.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}
.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}
.carousel-pagination {
  color: #3a94fd;
  font-weight: bold;
  font-size: 20px;
  width: 100vw;
  text-align: center;
  padding-bottom: 15px;
  margin-left: -15px;
}
.carousel-pagination--hide-lanthanides {
  color: white;
  text-decoration: underline;
}
.carousel-pagination--hide-lanthanides:before {
  content: url('/vrtx/dist/resources/images/group-4.png');
  padding-right: 8px;
}
a,
.vrtx-frontpage-box.vrtx-more-false > h2 a,
.featured-one-big-two-column .vrtx-featured-item .vrtx-box-content h2 a,
#comments-header-left a,
#main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.vrtx-search-results-container div.vrtx-search-results .result h2 a,
.add-comment-header {
  color: #355d98;
}
#main #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-title:focus,
#main .vrtx-resources .vrtx-daily-events-listing a.vrtx-title:focus,
#main #vrtx-daily-events .vrtx-resource a.vrtx-title:focus,
#main .vrtx-resources .vrtx-resource a.vrtx-title:focus,
#main #vrtx-daily-events .vrtx-daily-events-listing a.vrtx-title:hover,
#main .vrtx-resources .vrtx-daily-events-listing a.vrtx-title:hover,
#main #vrtx-daily-events .vrtx-resource a.vrtx-title:hover,
#main .vrtx-resources .vrtx-resource a.vrtx-title:hover {
  color: #355d98;
}
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*='button']):focus,
body:not(#vrtx-frontpage) td.elm-has-own-text:not([class*='person-listing']) a:not([class*='button']):focus,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']):focus,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']):focus,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']):focus,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']):focus,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*='button']):hover,
body:not(#vrtx-frontpage) td.elm-has-own-text:not([class*='person-listing']) a:not([class*='button']):hover,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']):hover,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']):hover,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']):hover,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']):hover,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*='button']).hover,
body:not(#vrtx-frontpage) td.elm-has-own-text:not([class*='person-listing']) a:not([class*='button']).hover,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']).hover,
body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']).hover,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']).hover,
body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']).hover {
  background-color: #355d98;
  -webkit-box-shadow: 0 0 0 2px #355d98;
  -moz-box-shadow: 0 0 0 2px #355d98;
  box-shadow: 0 0 0 2px #355d98;
}
#footer-wrapper #footers a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link):focus,
#footer-wrapper #footers a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link):hover {
  background-color: #355d98;
  -webkit-box-shadow: 0 0 0 2px #355d98;
  -moz-box-shadow: 0 0 0 2px #355d98;
  box-shadow: 0 0 0 2px #355d98;
  color: #fff;
}
.uio-app-line-top {
  display: none;
  background: #263239;
  height: 39px;
}
.uio-app-line-top .uio-logo {
  display: none;
}
.uio-app-line-top .uio-logo-wrapper {
  width: 970px;
  margin: 0 auto;
}
.uio-app-line-top .uio-app-name {
  font-size: 17px;
  font-size: 1.7rem;
  margin-top: -1px;
  margin-bottom: 0px;
}
.uio-app-line-top .uio-app-name .uio-acronym {
  font-size: 17px;
  font-size: 1.7rem;
  padding-right: 0.75em;
  background-position: 100% 0.5ex;
  background-size: 0.4em 0.85em;
}
.uio-app-line-top .uio-app-name .uio-host {
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: -1px;
  font-weight: 500;
}
html {
  background: #272727;
}
.head-menu-custom a,
.not-for-ansatte .head-menu > .vrtx-login-manage-component > a,
.not-for-ansatte .head-menu > ul > li a {
  color: #fff;
}
.head-menu-custom {
  float: right;
  margin-top: 28px;
  display: none;
}
.head-menu-custom li {
  display: inline-block;
  margin-left: 30px;
}
.not-for-ansatte #footer-wrapper,
.not-for-ansatte #head-wrapper {
  background: #272727;
}
.not-for-ansatte #head-wrapper {
  border-bottom: 1px solid #6f6f6f;
}
.not-for-ansatte #head-wrapper #head #header #logo {
  font-size: 40px;
  font-size: 4rem;
  color: #ffffff;
}
@media (min-width: 605px) {
  .not-for-ansatte #head-wrapper #head,
  .not-for-ansatte #head-wrapper #header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .not-for-ansatte #head-wrapper #head {
    height: 90px;
  }
  .not-for-ansatte #head-wrapper #header {
    flex-grow: 1;
  }
  .not-for-ansatte #head-wrapper .head-menu {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }
  .not-for-ansatte #head-wrapper .vrtx-login-manage-component {
    padding-left: 60px;
  }
  .not-for-ansatte #head-wrapper .uiologo {
    background: url("../images/uio-logo-no-white.svg") no-repeat center;
    background-size: contain;
    width: 200px;
    height: 50px;
  }
  .not-for-ansatte #head-wrapper .uiologo:focus {
    outline: auto;
  }
}
@media (max-width: 604px) {
  .not-for-ansatte #head-wrapper .uiologo {
    display: none;
  }
}
.not-for-ansatte #footer-wrapper {
  background: #272727;
  padding-bottom: 40px;
  position: relative;
  border-bottom: 110px solid #000;
}
.not-for-ansatte #footer-wrapper #footers {
  position: static;
}
.not-for-ansatte #footer-wrapper .uiologo {
  background: url("../images/uio-logo-no-white.svg") no-repeat left center;
  background-size: contain;
  display: block;
  position: absolute;
  bottom: -85px;
  height: 60px;
  width: 250px;
}
.not-for-ansatte #footer-wrapper .uiologo:focus {
  outline: auto;
}
.not-for-ansatte .vrtx-dropdown-link:focus-visible {
  outline: auto;
  outline-offset: 5px;
}
.not-for-ansatte .header-search,
.not-for-ansatte #globalnav,
.not-for-ansatte #bottomnav,
.not-for-ansatte .head-menu > *:not(.vrtx-login-manage-component) {
  display: none;
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  #head-wrapper .uio-app-line-top {
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #head-wrapper .uio-app-line-top .uio-logo-wrapper {
    width: auto;
    margin: 0;
  }
  #head-wrapper .uio-app-line-top .uio-app-name {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 24px;
    line-height: 2.4rem;
    margin-top: 0;
    margin-bottom: 0px;
  }
  #head-wrapper .uio-app-line-top .uio-app-name .uio-acronym {
    font-size: 12px;
    font-size: 1.2rem;
    padding-right: 0.7em;
    background-position: 100% 0.3ex;
  }
  #head-wrapper .uio-app-line-top .uio-app-name .uio-host {
    font-size: 10px;
    font-size: 1rem;
    margin-left: -1px;
  }
  .not-for-ansatte #head-wrapper #head {
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .not-for-ansatte #head-wrapper #head {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .not-for-ansatte #head-wrapper #head #header #logo {
    position: static;
    font-size: 25px;
    font-size: 2.05rem;
    line-height: 33px;
    line-height: 2.706rem;
    font-weight: bold;
  }
  .not-for-ansatte #menu-wrapper .menu {
    display: none;
  }
}
@media print {
  .not-for-ansatte #head-wrapper #head #header #logo {
    color: #000;
  }
}
