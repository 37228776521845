.ui-center {
  text-align: center;
}

.top-row-controllers {
  padding-right: 15px;
}

.carousel-container {
  position: relative;
  height: 100%;
  max-width: 960px;
  margin: 0px auto 50px auto;
  overflow: hidden;
  touch-action: pan-y;
}

.carousel-track {
  display: flex;
}

.carousel-card {
  flex: 0 0 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

.carousel-card-inner,
.table-mobile-element {
  display: flex;
  flex-direction: column;
  color: black;
  width: 70px;
}

.carousel-card-inner {
  height: 54px * 7;
  justify-content: flex-end;
  margin-left: 2px;
}

.table-mobile-element {
  position: relative;
  height: 52px;
  background-color: white;
  margin-top: 2px;

  &.table-mobile-element--center-element {
    border: 1px solid white;
  }

  &:not(.table-mobile-element--center-element) {
    opacity: 0.4;
  }

  .vrtx-periodic-table-element-atomic-number {
    position: absolute;
    right: 4px;
    font-size: 14px;
    line-height: 1.6;
  }

  .vrtx-periodic-table-element-name {
    margin-top: 8px;
    font-size: 22px;
  }

  .vrtx-periodic-table-element-full-name {
    font-size: 12px;
    line-height: 1;
  }
}

.table-mobile-element--fblock {
  background-color: black;
  border: 1px solid white;
}

.table-mobile-element__fblock-content {
  color: white;
  font-size: 16px;
  line-height: 1.6;

  &:after {
    content: url('/vrtx/dist/resources/images/pil.png');
    width: 14px;
  }
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination {
  color: #3a94fd;
  font-weight: bold;
  font-size: 20px;
  width: 100vw;
  text-align: center;
  padding-bottom: 15px;
  margin-left: -15px;
}

.carousel-pagination--hide-lanthanides {
  color: white;
  text-decoration: underline;
  &:before {
    content: url('/vrtx/dist/resources/images/group-4.png');
    padding-right: 8px;
  }
}
