@import '../variables.less';

#vrtx-periodic-table-element-listing {
  dt,
  dd {
    float: left;
  }
  dt {
    clear: both;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  dd {
    margin: 0;
    margin-bottom: 17px;
    font-weight: normal;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .vrtx-periodic-table-element-static {
    background: white;
    color: black;
    text-align: center;
    font-weight: bold;
    width: 153px;
    height: 153px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;

    .vrtx-periodic-table-element-atomic-number {
      .font-size(20);
    }

    .vrtx-periodic-table-element-name {
      .font-size(64);
    }
    .vrtx-periodic-table-element-full-name {
      .font-size(20);
    }
  }

  a.read-more-button {
    .font-size(26);
    color: white;
    margin-right: 25px;
    padding: 5px;
    text-decoration: underline;

    &:focus {
      .transform(scale(1.4));
      outline: 1px solid white;
    }
  }

  .row {
    padding: 0;
  }

  #vrtx-periodic-table.grid-container .vrtx-periodic-table-element-info {
    /* Hack to get full width (does not work with flex):
    width: 100vw;
    position: relative;
    padding-left: calc(~'(100vw - 970px) / 2');
    padding-right: calc(~'(100vw - 970px) / 2');*/

    display: flex;
    justify-content: center;

    padding-top: 10px;
    padding-bottom: 50px;

    background-color: @color-elements-blue-bg;
    color: white;

    @media @not-mobile {
      .vrtx-periodic-table-element-info-inner {
        width: 970px;

        article {
          display: grid;
          grid-template-columns: 1fr 1.2fr 2fr;
          .font-size(20);
          @media @tablet {
            .font-size(18);
          }

          justify-self: center;

          section {
            border-right: 1px solid white;
            margin-right: 35px;
            padding-right: 10px;

            .element-property {
              margin-right: 5px;
              font-weight: bold;
            }
          }

          section.element-box {
            grid-column-start: 1;
            grid-column-end: 2;

            .element-property {
              margin-top: 17px;
              display: inline-block;
            }
          }

          section.fact-listing {
            grid-column-start: 2;
            grid-column-end: 3;
          }
          section.introduction {
            grid-column-start: 3;
            grid-column-end: 4;
            border: none;
            margin-right: 0;
          }
        }
      }
    }

    // Mobile

    @media @mobile {
      height: 100vh;
      width: 100%;
      position: absolute;
      z-index: 99999;
      left: 0;
      top: 0;
      overflow-y: scroll;

      dl {
        overflow: hidden;
        dt {
          min-width: 167px;
        }
      }

      .close-button {
        margin-right: 13px;
      }

      .vrtx-periodic-table-element-info-inner {
        article {
          display: grid;
          grid-template-rows: auto auto auto;
          .font-size(16);
          justify-self: center;

          section {
            border-bottom: 1px solid white;
            margin-bottom: 25px;
            padding-bottom: 25px;

            margin-left: 30px;
            margin-right: 50px;
          }

          section.element-box {
            grid-row-start: 1;
            grid-row-end: 2;

            display: grid;

            .vrtx-periodic-table-element-static {
              grid-row-start: 1;
              grid-row-end: 5;
              grid-column-start: 1;
              grid-column-end: 2;
            }

            .element-property {
              display: inline-block;
              margin-right: 5px;

              grid-row-start: 2;
              grid-row-end: 2;
              grid-column-start: 2;
              grid-column-end: 3;

              font-weight: bold;
              text-align: center;
            }

            .element-box__element-value {
              grid-row-start: 3;
              grid-row-end: 4;
              grid-column-start: 2;
              grid-column-end: 3;

              text-align: center;
              font-style: italic;
            }
          }

          section.fact-listing {
            grid-row-start: 2;
            grid-row-end: 3;
          }
          section.introduction {
            grid-row-start: 3;
            grid-row-end: 4;
            border: none;
          }
        }
      }
    }
  }
}
