@import "../../../../../lib/uio1/profile/css/util.less";
@import "../../../../../vrtx/groups/design-uio1/src/resources/css/util.less";
@import 'variables.less';

.linkStyle(@color) {
  a,
  .vrtx-frontpage-box.vrtx-more-false > h2 a,
  .featured-one-big-two-column .vrtx-featured-item .vrtx-box-content h2 a,
  #comments-header-left a,
  #main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
  .vrtx-search-results-container div.vrtx-search-results .result h2 a,
  .add-comment-header {
    color: @color-link;

    &:hover,
    &:focus {
    }
  }

  #main {
    #vrtx-daily-events,
    .vrtx-resources {
      .vrtx-daily-events-listing,
      .vrtx-resource {
        a.vrtx-title {
          &:focus,
          &:hover {
            color: @color-link;
          }
        }
      }
    }
  }

  body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*='button']),
  body:not(#vrtx-frontpage) td.elm-has-own-text:not([class*='person-listing']) a:not([class*='button']),
  body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']),
  body:not(#vrtx-frontpage) ul:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']),
  body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > a:not([class*='button']),
  body:not(#vrtx-frontpage) ol:not([class^='vrtx-tags-']) li.elm-has-own-text > *:not(ul):not(ol) a:not([class*='button']) {
    //text-decoration: none;
    //border-bottom: 1px dotted @color;

    &:hover,
    &:focus {
      //border-bottom: none;
    }
  }
}
