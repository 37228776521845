h1, h2, .vrtx-periodic-table-element-toc-header, h3 {
  font-weight: bold;
}

h1 {
  .font-size(50);
  .line-height(58);
  margin-top: 0;
}

h2,
.vrtx-periodic-table-element-toc-header {
  .font-size(35);
  .line-height(45);
}

h3 {
  .font-size(24);
  .line-height(34);
}

caption, h1, h2, h3 {
  margin: 25px 0 15px 0;
}

body {
  .font-size(18);
  .line-height(30);
  color: #000;
}

#main p {
  margin: 15px 0 30px 0;
}

blockquote {
  border-left: 5px solid @color-elements-blue-bg;
  font-family: Arial,Helvetica,sans-serif;
  .font-size(22);
  .line-height(30);
  padding-left: 31px;

  &:before,
  &:after {
    margin-left: -35px;
  }
}

.grid-container blockquote, .uio-main blockquote {
  margin: 45px 0;
}

.introduction-div,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-introduction,
.vrtx-introduction-big,
.vrtx-organizational-unit-introduction {
  .font-size(22);
  .line-height(33);
  font-family: Arial,Helvetica,sans-serif;
}

.vrtx-image-listing-include-container-description, .vrtx-imagetext, .vrtx-img-container, figcaption {
  .font-size(16);
  .line-height(30);
  color: #000;
}

// Images

body p.image img,
body figure {
  margin: 45px 0;
}

body .align-right, body .image-right, body .right-img, body .vrtx-container-right, body .vrtx-introduction-image, body .vrtx-media-player.vrtx-container-right, body .vrtx-media-right, body p img.image-right {
  margin-top: 10px;
  margin-left: 45px;
}

body .align-left, body .image-left, body .left-img, body .vrtx-container-left, body .vrtx-media-left, body .vrtx-media-player.vrtx-container-left, body p img.image-left {
  margin-top: 10px;
  margin-right: 45px;
}

#vrtx-periodic-table-element  #main,
body {
  #element-back-link {
    background: @color-header-footer-bg;
    margin-bottom: 0;
    .font-size(18);
    font-weight: bold;
    padding: 8px 0;

    .row {
      width: 970px;
    }

    a {
      color: #fff;

      &:before {
        content: "";
        width: .9em;
        height: .9em;
        display: inline-block;
        margin-right: 15px;
        background: url('/vrtx/dist/resources/images/arrow-back.svg') no-repeat center left;
        background-size: 100% auto;
        margin-bottom: -2px;
      }
    }
  }
}

// Grid

#vrtx-periodic-table-element {
  #main #total-main #vrtx-content,
  #main {
    width: 100%;
  }

  #main {
    margin: 0;
  }

  .vrtx-introduction,
  h1,
  #main .grid-container .row {
    width: 660px;
    margin-left: auto;
    margin-right: auto;
  }
  #vrtx-periodic-table-element-header.grid-container .row {
    width: 970px;
  }
  #main .grid-container .row {
    padding-top: 0;
    padding-bottom: 0;

    >*:first-child {
      margin-top: 0;
    }
    >*:last-child {
      margin-bottom: 0;
    }
  }
}

// Facts

#main #vrtx-periodic-table-element-header {
  margin-bottom: 60px;

  &.has-image { // Has image
    #vrtx-periodic-table-element-header-information,
    #vrtx-periodic-table-element-header-facts {
      margin-top: 100px;
      margin-bottom: 81px;
    }
  }
}

#vrtx-periodic-table-element-header-information,
#vrtx-periodic-table-element-header-facts {
  margin-top: 100px;
}

#vrtx-periodic-table-element-header-information {
  width: 219px;
  height: 219px;
  border: 1px solid #000;
  background-color: #ffffff;
  float: left;
  font-weight: bold;
  text-align: center;
}

#vrtx-periodic-table-element-header-atomic-number,
#vrtx-periodic-table-element-header-symbol,
#vrtx-periodic-table-element-header-name {
  display: block;
  text-align: center;
  line-height: 1;
}

#vrtx-periodic-table-element-header-atomic-number {
  margin-top: 10px;
}

#vrtx-periodic-table-element-header-symbol {
  margin-top: 15px;
}

#vrtx-periodic-table-element-header-name {
  margin-top: 22px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

#vrtx-periodic-table-element-header-symbol {
  .font-size(75);
}

#vrtx-periodic-table-element-header-atomic-number,
#vrtx-periodic-table-element-header-name {
  .font-size(36);
}

#vrtx-periodic-table-element-header-facts {
  width: 613px;
  min-height: 219px;
  position: absolute;
  left: 219px;
  z-index: 1;
  margin-left: 63px;
  float: left;
  background-color: @color-elements-blue-bg;
  color: #fff;
  padding: 26px 39px 17px 39px;
  .font-size(20);

  a {
    color: #fff;
  }

  dl {
    overflow: hidden;

    &:nth-of-type(1) {
      dt {
        min-width: 200px;
      }
    }
    &:nth-of-type(2) {
      dt {
        min-width: 115px;
      }
    }
    &:nth-of-type(3) {
      dt {
        min-width: 255px;
      }
    }
    & ~ dl {
      border-top: solid 1px #ffffff;
      margin-top: 15px;
      padding-top: 15px;
      display: none;
    }
  }

  dt,
  dd {
    float: left;
  }
  dt {
    clear: both;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  dd {
    margin: 0;
  }
}

#vrtx-periodic-table-element-header-facts-show-more,
#vrtx-periodic-table-element-header-facts-show-less {
  display: block;
  clear: left;
  float: right;
  margin-top: -30px;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:after {
    content: "";
    background: url('/vrtx/dist/resources/images/chevron-down-white.svg') no-repeat 0 0;
    background-size: 100% auto;
    height: 1.2em;
    width: 1.2em;
    margin-bottom: -.4em;
    margin-left: 15px;
    display: inline-block;
  }
}

#vrtx-periodic-table-element-header-facts-show-less {
  margin-top: 10px;
  margin-bottom: 10px;

  &:after {
    background-image: url('/vrtx/dist/resources/images/chevron-up-white.svg');
    background-size: 100% auto;
  }
}

#vrtx-periodic-table-element-header-facts-show-less {
  display: none;
}

#vrtx-periodic-table-element-header .row {
  position: relative;
}

#vrtx-periodic-table-element-header-picture-credit {
  position: absolute;
  bottom: -40px;
  width: 100%;
  left: 0;
  text-align: right;
  color: #949494;

  &:before {
    content: '';
    display: inline-block;
    height: 1.2em;
    width: 1.4em;
    position: relative;
    margin-right: 5px;
    background: url('/vrtx/dist/resources/images/foto.svg') no-repeat 0 0;
    background-size: auto 80%;
    top: .8ex;
  }
}

// ToC

.vrtx-periodic-table-element-toc-header {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 0;
  padding-bottom: 10px;
  /*
  border-left: 5px solid @color-elements-blue-bg;
  padding-left: 50px;
  */
}

.vrtx-periodic-table-element-toc {
  margin: 0 auto 100px auto;
  overflow: hidden;
  width: 660px;
  /*
  border-left: 5px solid @color-elements-blue-bg;
  padding-left: 50px;
  */
  ul li {
    .font-size(20);
    margin: 0 0 20px 0;
    width: 50%;

    &:before {
      display: none;
    }

    a {
      &:before {
        content: "";
        height: 1.5em;
        width: 1.5em;
        display: inline-block;
        background: url('/vrtx/dist/resources/images/arrow-down.svg') no-repeat 0 0.5ex;
        .transform(rotate(-90deg));
        margin-right: 7px;
      }
    }

    &:nth-child(odd) {
      clear: both;
      float: left;
    }

    &:nth-child(even) {
      float: right;
    }

    &:before {
      content: ""
    }
  }
}

// Content read more

.vrtx-periodic-table-element-content-read-more {
  font-weight: bold;

  &[aria-expanded='true'] {
    &:after {
      background: url('/vrtx/dist/resources/images/chevron-up-blue.svg')
        no-repeat 0 0;
      background-size: 100% auto;
    }
  }

  &:after {
    content: "";
    background: url('/vrtx/dist/resources/images/chevron-down-blue.svg') no-repeat 0 0;
    background-size: 100% auto;
    height: 1em;
    width: 1em;
    margin-bottom: -0.4em;
    margin-left: 15px;
    display: inline-block;
  }

  ~ .vrtx-periodic-table-element-content-read-more--hidden {
    display: none !important;
  }
}

// Resource links

#main #vrtx-periodic-table-element-resource-links {
  background-color: @color-header-footer-bg;
  padding: 60px 0;

  #vrtx-periodic-table-element-resource-links-title {
    .font-size(35);
    font-weight: bold;
    display: block;
    margin-bottom: 40px;
  }

  &,
  a {
    color: #fff;
  }

  a {
    display: block;
    padding: 25px 0 25px 0;
    border-top: 2px solid @color-elements-blue-bg;
    .font-size(26);
    .line-height(34);
    position: relative;
    padding-right: 55px;

    &:after {
      content: "";
      height: 1.5em;
      width: 1.5em;
      display: inline-block;
      background: url('/vrtx/dist/resources/images/arrow-forward-blue.svg') no-repeat center right;
      background-size: 100% auto;
      position: absolute;
      top: 22px;
      right: 0;
    }
  }

  .row > span {
    &:last-child a {
      border-bottom: 2px solid @color-elements-blue-bg;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Responsive / mobile

@media @mobile {
   h1 {
     .font-size-resp(40);
     .line-height-resp(48);
   }

   h2,
   .vrtx-periodic-table-element-toc-header {
     .font-size-resp(30);
     .line-height-resp(38);
   }

   h3 {
     .font-size-resp(22);
     .line-height-resp(32);
   }

   body {
     .font-size-resp(18);
     .line-height-resp(30);
     color: #000;
   }

   blockquote {
     .font-size-resp(22);
     .line-height-resp(30);
   }

   .introduction-div,
   .vrtx-frontpage-box.introduction .vrtx-box-content,
   .vrtx-introduction,
   .vrtx-introduction-big,
   .vrtx-organizational-unit-introduction {
     .font-size-resp(20);
     .line-height-resp(33);
   }

   // Grid
   #main {
     padding: 0;
   }

   #main #vrtx-periodic-table-element-header {
     background-size: auto 219px;
     background-position: 0 0;
   }

   #vrtx-periodic-table-element {
     #main #total-main #vrtx-content,
     #main {
       width: 100%;
     }

     #main {
       margin-top: 0;
       margin-bottom: 0;
     }

     h1,
     .vrtx-introduction,
     #main .grid-container .row {
       width: 100%;
     }
     #main .grid-container .row {
       margin-left: 0;
       margin-right: 0;
     }
     h1,
     .vrtx-introduction {
       padding-left: 15px;
       padding-right: 15px;
     }
     #vrtx-periodic-table-element-header.grid-container .row {
       width: 100%;
       display: flex;
       flex-direction: column;

       #vrtx-periodic-table-element-header-information {
         order: 1;
       }
       #vrtx-periodic-table-element-header-picture-credit {
         order: 2;
         position: static;
       }
       #vrtx-periodic-table-element-header-facts {
         order: 3;
       }
     }
   }

  #vrtx-periodic-table-element #main #element-back-link {
    margin-top: 0;
    padding: 6px 0;
    .font-size-resp(18);

    .row {
      padding: 0 15px;
    }
  }

  // Facts

  #main #vrtx-periodic-table-element-header {
    margin-bottom: 0px;

    #vrtx-periodic-table-element-header-information,
    &.has-image #vrtx-periodic-table-element-header-information {
      margin-top: 30px;
      margin-bottom: 30px;
      float: left;
    }

    &.has-image #vrtx-periodic-table-element-header-information {
      margin-bottom: 70px;
    }

    #vrtx-periodic-table-element-header-facts,
    &.has-image #vrtx-periodic-table-element-header-facts {
      float: none;
      clear: both;
      width: 100%;
      height: auto;
      margin: 0 0 20px 0;
    }

    &.has-image #vrtx-periodic-table-element-header-facts {
      margin-top: 20px;
    }
  }

  #vrtx-periodic-table-element-header-facts {
    .font-size-resp(16);
    .line-height(26);
    position: static;
    min-height: 0;
    padding: 12px 20px 10px 20px;
    margin: 0;

    > a {
      font-weight: bold;
      .font-size-resp(18);

      &.visible {
        &:after {
          background-image: url('/vrtx/dist/resources/images/chevron-up-white.svg');
        }
      }

      &:after {
        content: "";
        background: url('/vrtx/dist/resources/images/chevron-down-white.svg') no-repeat 0 0;
        background-size: 100% auto;
        height: 1em;
        width: 1em;
        margin-bottom: -0.4em;
        margin-left: 15px;
        display: inline-block;
      }
    }

    dt {
      margin-bottom: 10px;
    }

    dl {
      overflow: hidden;

      &:nth-of-type(1) {
        border-top: solid 1px #ffffff;
        margin-top: 15px;
        padding-top: 15px;

        dt {
          min-width: 150px;
        }
      }
      &:nth-of-type(2) {
        dt {
          min-width: 85px;
        }
      }
      &:nth-of-type(3) {
        dt {
          min-width: 190px;
        }
      }
    }
  }

  #vrtx-periodic-table-element-header-facts-show-more,
  #vrtx-periodic-table-element-header-facts-show-less {
    display: none !important;
  }

  #vrtx-periodic-table-element-header-information {
    width: 112px;
    height: 112px;
  }

  #vrtx-periodic-table-element-header-atomic-number {
    margin-top: 5px;
  }

  #vrtx-periodic-table-element-header-symbol {
    margin-top: 10px;
  }

  #vrtx-periodic-table-element-header-name {
    margin-top: 10px;
  }

  #vrtx-periodic-table-element-header-symbol {
    .font-size-resp(40);
  }

  #vrtx-periodic-table-element-header-atomic-number,
  #vrtx-periodic-table-element-header-name {
    .font-size-resp(20);
  }

  #vrtx-periodic-table-element-header-picture-credit {
    padding: 0 15px;
    bottom: -20px;
    .font-size-resp(16);
  }

  // ToC

  .vrtx-periodic-table-element-toc {
    padding-left: 0;
    margin: 0 15px 50px 15px;
    width: auto;

    ul li {
      .font-size(18);
      width: auto;

      &:nth-child(2n+1),
      &:nth-child(2n) {
        float: none;
      }
    }
  }

  // Resource links

  #main #vrtx-periodic-table-element-resource-links {
    background-color: @color-header-footer-bg;
    padding: 50px 0;

    #vrtx-periodic-table-element-resource-links-title {
      .font-size-resp(26);
      margin-bottom: 30px;
    }

    a {
      .font-size-resp(20);
      .line-height-resp(30);
      padding-right: 40px;
    }
  }
}

@media print {
  #element-back-link {
    display: none;
  }
}
