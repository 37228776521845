/* CSS for Periodic table */

// Libraries
@import 'utils.less';

@import 'doctypes/element-article.less'; // Includes base typography
@import 'listing/element-article.less';
@import 'listing/filter-menu.less';
@import 'listing/element-info-row.less';
@import 'listing/filter-list.less';
@import 'listing/fullscreen-button.less';
@import 'listing/closable-popup.less';
@import 'listing/element-article-responsive.less';

// Links
.linkStyle(@color-link);
.backgroundOnFocusHoverUnderlinedText(@color-link);
.backgroundOnFocusHoverFooterUnderlinedText(@color-link);

// UiO line top. NOT IN USE
.uio-app-line-top {
  display: none; // NOT IN USE
  background: @color-app-line-top-bg;
  height: 39px;
  .uio-logo {
    display: none;
  }
  .uio-logo-wrapper {
    width: 970px;
    margin: 0 auto;
  }
  .uio-app-name {
    .font-size(17);
    margin-top: -1px;
    margin-bottom: 0px;
  }
  .uio-app-name .uio-acronym {
    .font-size(17);
    padding-right: 0.75em;
    background-position: 100% 0.5ex;
    background-size: 0.4em 0.85em;
  }
  .uio-app-name .uio-host {
    .font-size(16);
    margin-left: -1px;
    font-weight: 500;
  }
}

// Header
html {
  background: @color-header-footer-bg;
}
.head-menu-custom a,
.not-for-ansatte .head-menu > .vrtx-login-manage-component > a,
.not-for-ansatte .head-menu > ul > li a {
  color: #fff;
}
.head-menu-custom { // NOT IN USE
  float: right;
  margin-top: 28px;

  li {
    display: inline-block;
    margin-left: 30px;
  }

  display: none; // NOT IN USE
}
.not-for-ansatte {
  #footer-wrapper,
  #head-wrapper {
    background: @color-header-footer-bg;
  }
  #head-wrapper {
    border-bottom: 1px solid #6f6f6f;
    #head #header #logo {
      .font-size(40);
      color: #ffffff;
    }

    // large screen only
    @media (min-width: 605px) {
      #head,
      #header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      #head {
        height: 90px;
      }
      #header {
        flex-grow: 1;
      }
      .head-menu {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
      }
      .vrtx-login-manage-component {
        padding-left: 60px;
      }
      // uio logo (shown only on large screen)
      .uiologo {
        background: url("../images/uio-logo-no-white.svg") no-repeat center;
        background-size: contain;
        width: 200px;
        height: 50px;
        &:focus {
          outline: auto;
        }
      }
    }
    // small screen only
    @media (max-width: 604px) {
      .uiologo {
        display: none;
      }
    }
  }
}

// Footer
.not-for-ansatte {
  #footer-wrapper {
    background: @color-header-footer-bg;
    padding-bottom: 40px;
    position: relative;
    border-bottom: 110px solid #000; // space for uio logo
    #footers {
      position: static; // use wrapper as relative
    }
    // uio logo
    .uiologo {
      background: url("../images/uio-logo-no-white.svg") no-repeat left center;
      background-size: contain;
      display: block;
      position: absolute;
      bottom: -85px; // placed on wrapper bottom border
      height: 60px;
      width: 250px;
      &:focus {
        outline: auto;
      }
    }
  }
  .vrtx-dropdown-link {
    &:focus-visible {
      outline: auto;
      outline-offset: 5px;
    }
  }
}

// Hide things
.not-for-ansatte {
  .header-search,
  #globalnav,
  #bottomnav,
  .head-menu > *:not(.vrtx-login-manage-component) {
    display: none;
  }
}

@media @mobile {
  // UiO line top. NOT IN USE
  #head-wrapper .uio-app-line-top {
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    .uio-logo-wrapper {
      width: auto;
      margin: 0;
    }
    .uio-app-name {
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 24px;
      line-height: 2.4rem;
      margin-top: 0;
      margin-bottom: 0px;
    }
    .uio-app-name .uio-acronym {
      font-size: 12px;
      font-size: 1.2rem;
      padding-right: 0.7em;
      background-position: 100% 0.3ex;
    }
    .uio-app-name .uio-host {
      font-size: 10px;
      font-size: 1rem;
      margin-left: -1px;
    }
  }

  // Header

  .not-for-ansatte #head-wrapper #head {
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .not-for-ansatte #head-wrapper #head {
    padding-top: 15px;
    padding-bottom: 25px;

    #header #logo {
      position: static;
      .font-size-resp(25);
      .line-height-resp(33);
      font-weight: bold;
    }
  }
  .not-for-ansatte #menu-wrapper .menu {
    display: none;
  }
}

@media print {
  .not-for-ansatte #head-wrapper #head #header #logo {
    color: #000;
  }
}
