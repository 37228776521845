@import '../variables.less';

/* CSS arrow is from here: http://www.cssarrowplease.com/ */

.closable-popup {
  border-radius: 0px;
  pointer-events: auto;
  border: 2px solid @color-elements-blue-bg;
  background-color: @color-header-footer-bg;
  color: white;
  font-family: Helvetica;
  width: 350px;
  z-index: 10000;
  position: absolute;
  padding: 21px;

  &.filter-menu {
    top: 90px;
    right: -35px;
    width: 400px;
  }
  &.arrow-box--group-info {
    top: 50px;
    left: 30px;
  }
  &.arrow-box--period-info {
    left: 0px;
  }
  &.arrow-box--filter-info {
    margin-right: 180px;
    margin-left: -180px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-left: -530px; // IE HACK
      margin-top: -70px; // IE HACK
    }

    border: none;
    background-color: @color-elements-blue-bg;

    &:after {
      // This should actually only be on when --right is applied. Howto?
      border-left-color: @color-elements-blue-bg;
    }
  }
}

.arrow-box {
  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &--top:after,
  &--top:before {
    bottom: 100%;
    left: 62%;
  }

  &--left:after,
  &--left:before {
    right: 100%;
    top: 50%;
  }

  &--right:after,
  &--right:before {
    left: 100%;
    top: 50%;
  }

  &--group-info:after,
  &--group-info:before {
    left: 21%;
  }
  &--period-info:after,
  &--period-info:before {
    top: 55%;
  }
}

.arrow-box {
  &:after {
    border-color: rgba(39, 39, 39, 0);
    border-width: 15px;
  }
  &--top:after {
    border-bottom-color: @color-header-footer-bg;
    margin-left: -15px;
  }
  &--left:after {
    border-right-color: @color-header-footer-bg;
    margin-top: -15px;
  }
  &--right:after {
    border-left-color: @color-header-footer-bg;
    margin-top: -15px;
  }
}

.arrow-box {
  &:before {
    border-color: rgba(0, 118, 255, 0);
    border-width: 19px;
  }

  &--top:before {
    border-bottom-color: @color-elements-blue-bg;
    margin-left: -19px;
  }
  &--left:before {
    border-right-color: @color-elements-blue-bg;
    margin-top: -19px;
  }
  &--right:before {
    border-left-color: @color-elements-blue-bg;
    margin-top: -19px;
  }
}

.mobile-closable-popup {
  position: absolute;
  left: 0;
  top: 0px;
  overflow-y: scroll;

  border-radius: 0px;
  pointer-events: auto;
  border: 3px solid @color-elements-blue-bg;
  background-color: @color-header-footer-bg;
  color: white;
  font-family: Helvetica;
  z-index: 10000;
  padding: 21px;
  height: 100vh;
  width: 100vw;
}
