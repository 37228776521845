#fullscreen-button {
  padding: 0;
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  margin: 2px;
  border-radius: 26px;
  transition: 0.2s;
  background-color: transparent !important;

  &:hover,
  &:focus {
    border: 1px solid white;
  }

  &.fullscreen-off {
    &:hover,
    &:focus {
      .transform(scale(1.3));
    }
  }

  &.fullscreen-on {
    &:hover,
    &:focus {
      .transform(scale(0.8));
    }
  }

  .fullscreen-logo {
    fill: white;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
  }
}
