@import '../variables.less';

#vrtx-periodic-table {
  .filter-menu {
    a {
      font-size: 18px;
      color: white;
      cursor: pointer;
    }

    .remove-filter-button {
      background-color: transparent; // Because of inheritance
      border: none; // Because of inheritance
      margin: 0; // Because of inheritance
      padding: 0; // Because of inheritance

      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 35px 0;
      text-decoration: underline;
      color: white;
      font-weight: normal;

      &:hover,
      &:focus {
        font-weight: bold;
      }
    }

    .periodic-table-filter-category-title {
      background-color: transparent; // Because of inheritance
      border: none; // Because of inheritance
      margin: 0; // Because of inheritance
      padding: 0; // Because of inheritance

      display: flex;
      font-weight: bold;
      font-size: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      margin-top: 20px;
      width: 100%;
      color: white;
    }

    .periodic-table-filter-category-title.expanded {
      border-bottom: 2px solid @color-elements-blue-bg;
    }

    ul > li.periodic-table-filter-type {
      margin-bottom: 10px;
      font-size: 16px;
      cursor: pointer;

      .periodic-table-filter-name {
        background-color: transparent; // Because of inheritance
        border: none; // Because of inheritance
        margin: 0; // Because of inheritance
        padding: 0; // Because of inheritance

        font-size: 18px;
        margin-left: 30px;
        border-bottom: 1px solid @color-elements-blue-bg;
        font-weight: normal;
        color: white;

        &:hover,
        &:focus {
          font-weight: bold;
        }

        &--scale {
          font-size: 16px;
        }
      }
      .periodic-table-filter-name--scale {
        margin-left: 0px;
      }

      &:before {
        margin-top: 4px;
        margin-left: 0;
        overflow: visible;
        content: url('/vrtx/dist/resources/images/unselected.png');
      }

      &.disabled {
        &:before {
          content: url('/vrtx/dist/resources/images/unselected_grey.png');
        }
        color: #bfbfbf;

        .periodic-table-filter-name {
          color: #bfbfbf;
          border-color: #997d9a;
        }
      }

      &.selected {
        font-weight: bold;

        &:before {
          content: url('/vrtx/dist/resources/images/radiobutton.png');
        }
      }
    }

    .periodic-table-select-temperature-scale > li {
      padding-left: 30px;
      font-weight: normal;

      &:hover,
      &:focus {
        font-weight: bold;
      }
    }
  }

  .filter-menu-button-container {
    display: flex;
    height: min-content;
    justify-content: flex-end;
    position: relative;
  }

  button#filter-menu-button {
    background-color: transparent; // Because of inheritance
    border: none; // Because of inheritance
    margin: 0; // Because of inheritance
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding-right: 19px; // Only for focus border to be nice
    margin-right: -19px;

    &:focus {
      outline: 1px solid white;
    }

    .filter-menu-button__label {
      .font-size(20);
      font-weight: bold;
      border-bottom: 2px solid @color-elements-blue-bg;
      color: #efefef;
    }

    .filter-menu-button__icon {
      margin: 0px 8px 0px 0px;
    }
  }

  @media @mobile {
    button#filter-menu-button {
      margin-top: 0;
      margin-bottom: 0;

      .filter-menu-button__label {
        font-size: 16px;
      }
      .filter-menu-button__icon {
        width: 20px;
        height: 21px;
      }
    }

    .filter-menu-button-container {
      width: 100vw;
    }

    .periodic-table-filter-type__desktop-only {
      margin: 0 0 0 30px;
      font-style: italic;
    }
  }
}
