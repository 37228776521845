@import '../variables.less';

#vrtx-periodic-table-element-listing {
  #footer-wrapper {
    border-top: 1px solid #6f6f6f;
  }

  // Grid
  #main #total-main #vrtx-content,
  #main {
    width: 100%;
  }

  #main {
    margin-top: 0;
    margin-bottom: 0;

    .grid-container {
      .row {
        width: @periodic-table-page-width;
        @media @tablet {
          width: @periodic-tabler-tablet-page-width;
        }

        margin-left: auto;
        margin-right: auto;

        &.periodic-table {
          width: @periodic-table-page-width + 27.1px; // Count in period-numbering
          @media @tablet {
            width: @periodic-tabler-tablet-page-width + 27.1px;
          }
        }
        &.element-info {
          width: 100%; // Content is 970px
        }
      }
      &.grid-color-black {
        background: @color-header-footer-bg;
      }
    }
  }

  #vrtx-periodic-table-element-header.grid-container .row {
    width: @periodic-table-page-width;
    @media @tablet {
      width: @periodic-tabler-tablet-page-width;
    }
  }

  .vrtx-frontpage-box.grey-box,
  .vrtx-frontpage-box.grey-box-light,
  .vrtx-frontpage-box.grey-clip-box {
    padding-left: 0;
    padding-right: 0;
  }

  .top-row-controllers {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  #vrtx-periodic-table {
    display: flex;
    flex-direction: column;

    padding: 0 0 0 0;
    margin-bottom: 0;

    .vrtx-box-content {
      display: block;
      margin-bottom: 60px;
    }

    #vrtx-periodic-table-main {
      margin-bottom: 5px;
    }
    #vrtx-periodic-table-separate {
      margin-top: 20px;
    }

    table,
    table td,
    table th {
      border: none;
    }
    table {
      width: 1%;
    }
    #vrtx-periodic-table-separate {
      margin-left: ((@periodic-element-size + 2px) * 2);

      @media @tablet {
        margin-left: ((@periodic-element-tablet-size + 2px) * 2);
      }
    }
    table td {
      padding: 0;
      margin: 0;
      border: 2px solid transparent;
      border-width: 0 2px 2px 0;
      line-height: 1;

      &.vrtx-periodic-table-element-special {
        .font-size(18);
        color: white;

        &:not(.contains-expand-button) {
          padding: 8px 0 0 8px;
        }

        .vrtx-periodic-table-expand-separate-table {
          // Reset UiO button:
          border: none;
          margin: 0;
          padding: 0;

          // Necessary to make "a" and "button" equal:
          font-family: Helvetica, sans-serif;
          font-weight: normal;
          background: #272727;
          color: white;
          line-height: 1.2;
        }
      }

      &.numbering-info {
        width: @periodic-element-size;
        @media @tablet {
          width: @periodic-element-tablet-size;
        }
        color: white;
        .font-size(20);
        text-align: center;
        padding-top: 8px;

        &.table-period {
          padding: 15px 15px 0 0;
        }
      }
    }
  }

  .vrtx-periodic-table-element-container {
    display: flex;
    justify-content: center;
    position: relative; /* IE center HACK */

    .vrtx-periodic-table-element-filter-info {
      border-radius: 4px;

      min-width: (@periodic-element-size * 1.7);
      min-height: (@periodic-element-size * 1.7) / 3.5;
      margin-top: 4 + (@periodic-element-size + @periodic-element-size * 1.7) /
        2;

      @media @tablet {
        min-width: (@periodic-element-tablet-size * 1.7);
        min-height: (@periodic-element-tablet-size * 1.7) / 3.5;
        margin-top: 4 +
          (@periodic-element-tablet-size + @periodic-element-tablet-size * 1.7) /
          2;
      }

      background-color: white;
      text-align: center;
      position: absolute;
      display: none;
      padding: 10px;
      white-space: nowrap;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transform: translateX(-50%); /* IE center HACK */
        left: 50%; /* IE center HACK */
      }
    }

    &:focus.vrtx-periodic-table-element-filter-info,
    &:hover .vrtx-periodic-table-element-filter-info {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
    }
  }

  .vrtx-periodic-table-element {
    // Reset UiO button:
    border: none;
    border-radius: 0px;
    margin: 0;
    padding: 0;

    // Necessary to make "a" and "button" equal:
    font-family: Helvetica, sans-serif;
    font-weight: normal;
    background: #fff;

    display: inline-block;

    width: @periodic-element-size;
    height: @periodic-element-size;

    @media @tablet {
      width: @periodic-element-tablet-size;
      height: @periodic-element-tablet-size;
    }

    line-height: 1.3;
    color: #000;
    cursor: pointer;

    &.selected {
      border: 3px solid @color-elements-blue-bg;
      z-index: 1;
      .transform(scale(1.1));
    }

    .vrtx-periodic-table-element-full-name {
      display: none;
      text-align: center;
    }

    .vrtx-periodic-table-element-atomic-number,
    .vrtx-periodic-table-element-name {
      display: block;
      text-align: center;
      font-weight: bold;
    }

    .vrtx-periodic-table-element-atomic-number {
      .font-size(14);
    }

    .vrtx-periodic-table-element-name {
      .font-size(24);
      @media @tablet {
        .font-size(22);
      }
    }

    &:focus,
    &:hover {
      .transform(scale(1.7));
      border: 2px solid @color-header-footer-bg;
      text-decoration: none;
      z-index: 9999;

      &.selected {
        border-color: @color-elements-blue-bg;
      }

      .vrtx-periodic-table-element-atomic-number {
        .font-size(8);
        margin-top: 3px;
      }

      .vrtx-periodic-table-element-name {
        .font-size(18);
        @media @tablet {
          .font-size(15);
          line-height: 1.2;
        }
      }
      .vrtx-periodic-table-element-full-name {
        display: block;
        .font-size(7);
      }
    }
  }

  .table-mobile-element,
  .vrtx-periodic-table-element {
    &.subdued {
      opacity: 0.4;
    }

    &.no-filter {
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 10px,
        #dedede 10px,
        #dedede 20px
      );
    }
    &.filter1 {
      background: @color-filter1;
    }
    &.filter2 {
      background: @color-filter2;
    }
    &.filter3 {
      background: @color-filter3;
    }
    &.filter4 {
      background: @color-filter4;
    }
    &.filter5 {
      background: @color-filter5;
    }
    &.filter6 {
      background: @color-filter6;
    }
    &.filter7 {
      background: @color-filter7;
    }
    &.filter8 {
      background: @color-filter8;
    }
    &.filter9 {
      background: @color-filter9;
    }

    &.many-filter1 {
      background: @color-many-filter1;
    }
    &.many-filter2 {
      background: @color-many-filter2;
    }
    &.many-filter3 {
      background: @color-many-filter3;
    }
    &.many-filter4 {
      background: @color-many-filter4;
    }
    &.many-filter5 {
      background: @color-many-filter5;
    }
    &.many-filter6 {
      background: @color-many-filter6;
    }
    &.many-filter7 {
      background: @color-many-filter7;
    }
    &.many-filter8 {
      background: @color-many-filter8;
    }
    &.many-filter9 {
      background: @color-many-filter9;
    }
    &.many-filter10 {
      background: @color-many-filter10;
    }
  }

  .close-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__button {
      background-color: transparent; // Because of inheritance
      border: none; // Because of inheritance
      margin: 0; // Because of inheritance
      padding: 5px; // Because of inheritance

      display: flex;
      align-items: center;
      font-weight: bold;
      color: white;
      .font-size(18);

      &:focus {
        outline: 1px solid white;
      }
    }

    &--with-separator {
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      padding-bottom: 10px;
      margin-bottom: 25px;
    }
    &__icon {
      margin-left: 7px;
    }
  }

  .vrtx-periodic-table-info-container {
    position: relative;

    .arrow-box--group-info,
    .arrow-box--period-info {
      .font-size(16);

      .close-button {
        margin-bottom: 11px;
      }
    }
  }

  .vrtx-periodic-table-info {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    span {
      .font-size(16);
      line-height: 1.5;
      font-weight: bold;
      color: #efefef;
    }

    &.group {
      margin-left: 27px;

      span {
        border-bottom: 1px solid @color-elements-blue-bg;
      }
      img {
        margin: 0 10px;
      }
    }

    &.period {
      flex-direction: column;
      position: absolute;
      margin-left: -50px;
      margin-top: 30px;

      span {
        border-left: 1px solid @color-elements-blue-bg;
        writing-mode: vertical-rl;
      }
      img {
        margin: 10px 0;
      }
    }
  }
}

// Responsive / mobile

@media @mobile {
  #head {
    background-color: #363534;
  }

  #footer-wrapper {
    padding-left: 20px;
  }

  #main #total-main #vrtx-content {
    height: 100vh;

    &:not(.popup-is-active) {
      display: contents;
    }
  }

  #vrtx-periodic-table-element-listing {
    #fullscreen-button,
    .periodic-table,
    #vrtx-periodic-table-separate {
      display: none;
    }

    #offcanvas-outer-wrapper {
      #head-wrapper {
        border-bottom: none;
      }
    }

    #vrtx-periodic-table {
      .showing-element-info {
        height: 100vh;
      }

      &.grid-color-black {
        height: 100%;
      }
    }

    #vrtx-periodic-table-element-listing #main {
      .grid-container .row {
        width: auto;
        margin-left: 0;
        margin-right: 0;
      }
    }

    #periodic-table {
      .vrtx-periodic-table-element-container {
        width: @periodic-element-size + 18px;
      }

      .vrtx-periodic-table-element {
        width: @periodic-element-size + 18px;
      }

      @media @tablet {
        .vrtx-periodic-table-element-container {
          width: @periodic-element-tablet-size + 18px;
        }

        .vrtx-periodic-table-element {
          width: @periodic-element-tablet-size + 18px;
        }
      }

      .vrtx-periodic-table-element {
        .vrtx-periodic-table-element-full-name {
          display: inline;
          text-align: center;
          .font-size(14);
        }

        .vrtx-periodic-table-element-atomic-number {
          .font-size(14);
          text-align: right;
        }

        .vrtx-periodic-table-element-name {
          .font-size(22);
        }
      }
    }
  }
}
